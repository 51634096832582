<template>
  <div class="seller-info-modal" ref="tableRef">
    <artmisads-modal title="Seller Info" :open="true" width="800px" @cancel="onCancel" :footer="null"
      :getContainer="() => $refs.tableRef">
      <a-spin :spinning="showLoading">
        <div class="content">
          <div class="flex-wrapper">
            <div class="flex-item">
              <h2>Account Information</h2>
              <div class="info-item">Account Name<span class="info-value">{{ accountInfo.accountName || '-' }}</span>
              </div>
              <div class="info-item">DBA Name<span class="info-value">{{ accountInfo.dbaName || 'NULL!' }}</span></div>
              <div class="info-item">Email<span class="info-value">{{ accountInfo.operationContactEmail || '-' }}</span></div>
              <div class="info-item">Phone<span class="info-value">{{ accountInfo.operationContactNumber || 'NULL!' }}</span></div>
              <div class="info-item">Register Date<span class="info-value">{{ accountInfo.registryTimestamp ?
                moment(accountInfo.registryTimestamp).format('YYYY-MM-DD') : '-' }}</span></div>
            </div>
            <div class="flex-item">
              <h2>Finance Information</h2>
              <div class="info-item">Total Amount<span class="info-value">{{ financeInfo.totalAmount ?
                financeInfo.currencySymbol : ''
                  }}{{ financeInfo.totalAmount || '0' }}</span>
              </div>
              <div class="info-item">Paid<span class="info-value">{{ financeInfo.totalPaidAmount ?
                financeInfo.currencySymbol : '' }}{{
                    financeInfo.totalPaidAmount || '0' }}</span></div>
              <div class="info-item">Peding<span class="info-value">{{ financeInfo.totalPendingAmount ?
                financeInfo.currencySymbol : ''
                  }}{{ financeInfo.totalPendingAmount || '0' }}</span>
              </div>
              <div class="info-item">Tax No.<span class="info-value">{{ accountInfo.taxRegistrationNumber ||
                'NULL!' }}</span></div>
              <div class="info-item">Payment Term<span class="info-value">{{ financeInfo.paymentTerm || '-' }}</span>
              </div>
            </div>
          </div>
          <div class="flex-wrapper">
            <div class="flex-item brands">
              <h2>Brand Information<a href="javascript:void(0)" v-if="moreBrands.length > 3" @click="onMoreBrands">See
                  More</a></h2>
              <div class="brand-item" v-for="brand in brands" :key="brand.id">
                <div class="brand-info">
                  <span>{{ brand.amazonBrandName }}</span>
                  <span>{{ brand.countryCode }}</span>
                  <showStatus :isActive="brand.status == 'active'" :noBorder="true" :text="['Active', 'Inactive']" />
                </div>
                <div class="active-products">Active Products<span class="info-value">{{ brand.activeProductCount || 0
                    }}</span></div>
                <div class="inactive-products">Inactive Products<span class="info-value">{{ brand.inactiveProductCount
                  ||
                  0 }}</span></div>
              </div>
            </div>
            <div class="flex-item"
              v-if="billingAddress?.contactName || billingAddress?.email || billingAddress?.phone || billingAddress?.address || billingAddress?.city || billingAddress?.state || billingAddress?.country || billingAddress?.zipCode">
              <h2>Billing Information</h2>
              <div class="info-item">Billing Contact Person<span class="info-value">{{ billingAddress.contactName || '-'
                  }}</span></div>
              <div class="info-item">Billing Contact Email<span class="info-value">{{ billingAddress.email || '-'
                  }}</span></div>
              <div class="info-item">Billing Contact Phone<span class="info-value">{{ billingAddress.phone || '-'
                  }}</span></div>
              <div class="info-item contact-address">Billing Contact Address
                <span>{{ billingAddress.address }}, {{ billingAddress.city }}</span>
                <span>{{ billingAddress.state }}</span>
                <span>{{ billingAddress.country }}</span>
                <span>{{ billingAddress.zipCode }}</span>
              </div>
            </div>
            <div class="flex-item" v-else>
              <h2>Billing Information</h2>
              <div class="no-info">No Billing Information</div>
            </div>
          </div>
          <div class="flex-wrapper">
            <div class="flex-item">
              <h2>IM Information</h2>
              <div class="info-item">Wechat<span class="info-value">{{ sellerImContactInfo.wechat || '-' }}</span>
              </div>
              <div class="info-item">Dingtalk<span class="info-value">{{ sellerImContactInfo.dingtalk || '-' }}</span></div>
              <div class="info-item">Slack<span class="info-value">{{ sellerImContactInfo.slack || '-' }}</span></div>
            </div>
          </div>
        </div>
      </a-spin>
    </artmisads-modal>
    <artmisads-modal class="more-brands-modal" :title="null" :open="showMoreBrandsModal" width="800px"
      @cancel="hideMoreBrandsModal" :getContainer="() => $refs.tableRef" :footer="null">
      <artmisads-table :columns="moreBrandsColumns" :dataSource="moreBrands" :pagination="moreBrandsPagination">
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'status'">
            <showStatus :isActive="record.status == 'active'" :noBorder="true" :text="['Active', 'Inactive']" />
          </template>
          <template v-else-if="column.key === 'products'">
            Active Products: {{ record.activeProductCount }}<br>Inactive Products:
            {{ record.inactiveProductCount }}
          </template>
          <template v-else-if="column.key === 'marketplace'">
            {{ record.countryCode.toUpperCase() }}
          </template>
        </template>
      </artmisads-table>
    </artmisads-modal>
  </div>
</template>

<script setup>
import moment from 'moment';
import { onMounted, ref } from 'vue';
import { operatorSellerApi } from '~/server/index';
import showStatus from '~/components/showStatus.vue';

const sellerImContactInfo = ref({});
const brands = ref([]);
const moreBrands = ref([]);
const accountInfo = ref({});
const financeInfo = ref({});
const showLoading = ref(false);
const billingAddress = ref(null);
const showMoreBrandsModal = ref(false);
const props = defineProps(['seller']);
const emit = defineEmits(['update:visible']);

const moreBrandsColumns = ref([
  {
    title: 'Brand',
    dataIndex: 'amazonBrandName',
  },
  {
    key: 'status',
    title: 'Status',
    dataIndex: 'status',
  },
  {
    key: 'products',
    title: 'Products',
  },
  {
    key: 'marketplace',
    title: 'MarketPlace',
    dataIndex: 'countryCode',
  }
]);

const moreBrandsPagination = ref({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: false,
});

const onCancel = () => {
  emit('update:visible', false);
};

const onMoreBrands = () => {
  showMoreBrandsModal.value = true;
};

const hideMoreBrandsModal = () => {
  showMoreBrandsModal.value = false;
};

onMounted(() => {
  showLoading.value = true;
  showMoreBrandsModal.value = false;

  operatorSellerApi.getSellerDetails({ sellerId: props.seller.id, countryCode: props.seller.countryCode }).then((res) => {
    brands.value = res.sellerBrands.slice(0, 3);
    sellerImContactInfo.value = res.sellerImContactInfo || {};
    billingAddress.value = res.sellerBillingAddress;
    accountInfo.value = { ...res.sellerAccountInfo, email: res.email };
    financeInfo.value = { ...res.sellerBillingAgg, taxNum: res.sellerAccountInfo.taxRegistrationNumber };

    if (res.sellerBrands.length > 3) {
      moreBrands.value = res.sellerBrands;
      moreBrandsPagination.value.total = res.sellerBrands.length;
    }

    showLoading.value = false;
  });
});

</script>

<style lang="less" scoped>
.flex-wrapper {
  gap: 16px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .flex-item {
    flex: 1;
    padding: 16px;
    border-radius: 8px;
    box-shadow: var(--section-box-shadow-5);

    >h2 {
      font-size: 20px;
      margin-top: -10px;
      position: relative;

      &:after {
        top: 15%;
        left: -8px;
        width: 2px;
        height: 70%;
        content: "";
        position: absolute;
        background: var(--primary);
      }
    }
  }

  .info-item {
    height: 30px;
    color: #999;
    display: flex;
    line-height: 30px;
    align-items: center;
    justify-content: space-between;

    .info-value {
      color: #333;
      overflow: hidden;
      max-width: 180px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .brands {
    padding-bottom: 16px;

    >h2 {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      justify-content: space-between;

      >a {
        font-size: 16px;
        color: var(--primary);

        &:hover {
          color: var(--primary-hover);
        }
      }
    }
  }

  .brand-item {
    color: #333;
    border-radius: 8px;
    padding: 10px 20px 16px;
    box-shadow: var(--section-box-shadow-5);

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    .brand-info {
      display: flex;
      margin-bottom: 6px;
      align-items: center;
      justify-content: space-between;
    }

    .active {
      color: var(--green-1)
    }

    .inactive {
      color: var(--red-1)
    }

    .active-products,
    .inactive-products {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .contact-address {
    gap: 6px;
    height: auto;
    display: flex;
    align-items: start;
    flex-direction: column;

    >span {
      color: #333;
      line-height: 24px;
    }
  }

  .inactive-info {
    color: var(--red-1);
  }
}

// no billing address
.no-info {
  color: #999;
  padding: 20px 0;
  text-align: center;
}

:deep(.more-brands-modal) {
  .ant-modal-close {
    top: 4px;
    right: 4px;
  }

  .artmisads-table {
    padding-bottom: 0;
  }

  .ant-table {
    margin-top: 10px;
  }

  .ant-table-cell {
    padding: 8px
  }
}
</style>