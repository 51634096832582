<template>
  <div class="seller-account-info">
    <artmisads-modal :open="true" width="800px" :footer="false" @cancel="emit('update:visible', false)">
      <div class="content">
        <a-spin :spinning="showLoading">
          <h2>Account Info</h2>
          <div class="flex-wrapper">
            <div class="flex-item">
              <div class="flex-item-label">Account Name</div>
              <div class="flex-item-value account-name">{{ accountInfo.accountName || '-' }}<br>
                {{ accountInfo.email }}
              </div>
            </div>
            <div class="flex-item">
              <div class="flex-item-label">Marketplace</div>
              <div class="flex-item-value">{{ seller.countryCode }}</div>
            </div>
            <div class="flex-item">
              <div class="flex-item-label">Registry Date</div>
              <div class="flex-item-value">{{ accountInfo.registryTimestamp ?
                moment(accountInfo.registryTimestamp).format('YYYY-MM-DD') : '-' }}</div>
            </div>
          </div>
          <h2>Payment Details</h2>
          <div class="flex-wrapper">
            <div class="flex-item">
              <div class="flex-item-label">Total Amount</div>
              <div class="flex-item-value">{{ billingAgg.totalAmount ? currency : '' }}{{ billingAgg.totalAmount || 0 }}
              </div>
            </div>
            <div class="flex-item">
              <div class="flex-item-label">Paid Amount</div>
              <div class="flex-item-value">{{ billingAgg.totalPaidAmount ? currency : '' }}{{ billingAgg.totalPaidAmount
                || 0 }}</div>
            </div>
            <div class="flex-item">
              <div class="flex-item-label">Pending Amount</div>
              <div class="flex-item-value">{{ billingAgg.totalPendingAmount ? currency : '' }}{{
                billingAgg.totalPendingAmount || 0 }}</div>
            </div>
          </div>
          <artmisads-table :columns="columns" :dataSource="billingList"
            :pagination="pagination.total > pagination.pageSize ? pagination : false">
            <template #bodyCell="{ column, text }">
              <template v-if="['pending', 'paidAmount', 'amount'].indexOf(column.key) != -1">
                <span v-if="text">
                  {{ currency }}{{ text }}
                </span>
                <span v-else>0</span>
              </template>
              <template v-else-if="column.key === 'date'">
                {{ moment(text).format('YYYY-MM') }}
              </template>
            </template>
          </artmisads-table>
        </a-spin>
      </div>
    </artmisads-modal>
  </div>
</template>

<script setup>
import moment from 'moment';
import { ref, computed } from 'vue';
import { operatorSellerApi } from '~/server/index';
import { CURRENCY_MAPPER } from '~/contants/common';

const emit = defineEmits(['update:visible']);
const { seller } = defineProps(['seller'])
const accountInfo = ref({});
const billingAgg = ref({});
const billingList = ref([]);
const countryCode = ref(seller.countryCode);
const showLoading = ref(false);
const currency = computed(() => CURRENCY_MAPPER[countryCode.value]?.currency || '$');
const columns = [{
  key: 'date',
  title: 'Date',
  dataIndex: 'billingDateTimestamp',
},
{
  key: 'amount',
  title: 'Amount',
  dataIndex: 'amount',
},
{
  title: 'Paid',
  dataIndex: 'paidAmount',
  key: 'paidAmount',
},
{
  key: 'pending',
  title: 'Pending',
  dataIndex: 'pendingAmount',
},
{
  title: 'Status',
  dataIndex: 'status',
  key: 'status',
}]
const pagination = ref({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: false,
  hideOnSinglePage: true,
  onChange: () => {
    getBillingList()
  },
})

function getBillingList() {
  showLoading.value = true

  const params = {
    sellerId: seller.id,
    countryCode: seller.countryCode,
    page: pagination.value.current,
    size: pagination.value.pageSize,
  }

  operatorSellerApi.getSellerPayments(params).then((data) => {
    showLoading.value = false

    if (data) {
      if (params.page === 1) {
        billingAgg.value = data.sellerBillingAgg;
        accountInfo.value = { ...data.sellerAccountInfo, email: data.email };
        countryCode.value = data.countryCode || 'US';
        pagination.value.total = data.sellerBillings.total
      }

      if (data.sellerBillings.total) {
        billingList.value = data.sellerBillings.data.map(item => {
          return {
            ...item,
            // todo: 精度问题
            pendingAmount: (item.amount - item.paidAmount).toFixed(2),
            status: item.status.split('_').map(i => {
              return i.charAt(0).toUpperCase() + i.slice(1)
            }).join(' ')
          }
        });
      }
    }
  })
}

getBillingList()
</script>

<style lang="less" scoped>
.content {
  h2 {
    color: #333;
    font-size: 16px;

    &:not(:first-child) {
      margin-top: 20px;
    }
  }
}

.flex-wrapper {
  gap: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex-item {
  flex: 1;
  gap: 10px;
  display: flex;
  align-items: center;

  &-label {
    color: #999;
    font-size: 14px;
    white-space: nowrap;
  }

  &-value {
    color: #333;
    font-size: 16px;
    white-space: nowrap;

    &.account-name {
      overflow: hidden;
      max-width: 200px;
      line-height: 18px;
      text-overflow: ellipsis;
    }
  }
}

.artmisads-table {
  margin-top: 30px;
}
</style>
